<template>
  <div class="modal" transition="modal">
    <div :class="{ 'modal-content-large': large }" class="modal-bg">
      <slot name="content" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    large: {
      default: false,
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  background-color: rgba(0, 0, 0, 0.4);
  @apply top-0 left-0 fixed z-20 w-full h-full overflow-hidden flex justify-center align-middle;
}

.modal-content-large {
  width: 85% !important;
  padding: 1rem !important;
  margin-top: 0.5rem !important;
}

.modal-bg {
  @apply bg-white m-auto p-5 pb-6 w-3/4 text-center shadow-box-lg rounded-theme-large;
}
</style>
